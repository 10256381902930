.root {
}

.boxSx {
  display: none;
}

@screen carouselMd {
  .boxSx {
    display: block;
    width: 100px;
    position: absolute;
    top: 0;
    left: -62px;
    height: 100%;
    border-top: 0.5px solid theme("colors.black");
    border-bottom: 0.5px solid theme("colors.black");
  }
}

.boxDx {
  display: none;
}

@screen carouselMd {
  .boxDx {
    display: block;
    width: 100px;
    position: absolute;
    top: 0;
    right: -62px;
    height: 100%;
    border-top: 0.5px solid theme("colors.black");
    border-bottom: 0.5px solid theme("colors.black");
    @apply text-right;
  }
}

.line {
  border-bottom: 0.5px solid theme("colors.black");
  @apply relative;
}

.lineTitle {
  left: 0;
  top: -10px;
  @apply absolute w-full text-center;
}

.lineTitle span {
  background-color: theme("colors.white");
  font-family: Quattrocento;
  letter-spacing: 1.8px;
  font-size: 10px;
  line-height: 16px;
  @apply px-3;
}

@screen carouselMd {
  .lineTitle span {
    font-size: 14px;
    line-height: 24px;
  }
}

.slide {
  height: 26px;
  @apply py-5 pl-4;
}

@screen carouselMd {
  .slide {
    height: 40px;
    @apply py-6 pl-0 pr-16;
  }
}

.ctaSlide {
  @apply flex items-center h-full py-0;
}

.arrow {
  font-size: 46px;
  line-height: 80px;
  @apply h-full mx-2 outline-none cursor-pointer;
}

.arrowEnd {
  display: none !important;
}

.arrowStart {
  display: none !important;
}

.shadow {
  width: 180px;
  opacity: 1;
  transition: opacity 0.5s ease;
  background: transparent linear-gradient(90deg, #ffffff00 0%, #ffffff 100%) 0%
    0% no-repeat padding-box;
  @apply absolute top-0 right-0 h-full md:hidden;
}

.shadowEnd {
  opacity: 0;
}

.shadowText {
  width: 28px;
  @apply z-1;
}
